export var EsteiraTech = {
    debug: true,
    init: function(){
        console.log('init EsteiraTech')
        EsteiraTech.loading.in();
        window.onload = function(){
            EsteiraTech.$startPage?.map( (item)=> (console.log('init '+item[0]),item[1]()) )
            if( EsteiraTech.debug ){
                window.EsteiraTech = EsteiraTech;
            }
            EsteiraTech.loading.out();
        }
    },
    loading:{
        status: false,
        in: function(container="body",id=''){
            if( id && $('#'+id).length > 0 ){
                return false;
            }else{
                document.querySelector(container).insertAdjacentHTML('beforebegin', (container=='body'?this.contentBody:this.content).replace('@@id@@',id) );
            }
        },
        out: function(id=''){
            if( id ){
                if( $('#'+id).length > 0 ){
                    document.querySelector("#"+id).remove();
                }
            }else{
                document.querySelector(".loading-animate-item").remove();
            }
        },
        contentBody: `
            <div id="@@id@@" class="loading-animate-item" style="width: 100%;height: 100%;position: fixed;align-items: center;display: flex;z-index: 99999999999;justify-content: center;background: rgba(255,255,255,0.4)">
                <span class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </span>
            </div>
        `,
        content: `
            <div id="@@id@@" class="loading-animate-item" style="width: 100%;height: 100%;position: absolute;align-items: center;display: flex;z-index: 99999999999;justify-content: center;background: rgba(255,255,255,0.4);top: 0;bottom: 0;padding: 0;left: 0;right: 0;">
                <span class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </span>
            </div>
        `,
    },
    $startPage: [],
    $: [],
    add: function(name,func){
        this.$[name] = func;
    },
    startPage: function(name,func){
        this.$startPage.push([name,func]);
    },
    $data: [],
    $get: function(name){
        return typeof this.$data[name].value !== 'undefined' ? this.$data[name].value : null
    },
    onChange: function(name,callback=()=>{}){
        if( typeof this.$data[name] === 'undefined' ){
            this.$data[name] = {value: null, onChange: []};
        }
        this.$data[name].onChange.push(callback);
        //callback(this.$get(name));
    },
    $onChange: function(name){
        if( typeof this.$data[name].onChange !== 'undefined' ){
            this.$data[name].onChange.map(function(item){
                item(EsteiraTech.$get(name));
            })
        }
        return;
    },
    set: function (name,value) {
        if( typeof this.$data[name] === 'undefined' ){
            this.$data[name] =  {value: value, onChange: []};
        }else{
            this.$data[name].value = value;
            this.$onChange(name);
        }
    },

};

// EsteiraTech.onChange('exemploObserverVar',function(val) {
//     alert("exemploObserverVar 1 " + val);
// })

// EsteiraTech.onChange('exemploObserverVar',function(val) {
//     alert("exemploObserverVar 2 " + val);
// })

// EsteiraTech.set('exemploObserverVar',' valor variavel ')
